import React from 'react';
import 'react-table/react-table.css';
import SiteWrapper from '../../SiteWrapper';
import { Grid } from 'tabler-react';
import CreateIncomingTransaction
    from 'src/components/Content/Configuration/IndustraStatement/CreateIncomingTransaction';
import CreateOutgoingTransaction
    from 'src/components/Content/Configuration/IndustraStatement/CreateOutgoingTransaction';

const IndustraStatement = () => {
    return (
        <SiteWrapper title='Industra Statement'>
            <Grid.Row>
                <Grid.Col md={6} sm={6} xs={12}>
                    <CreateIncomingTransaction />
                </Grid.Col>
                <Grid.Col md={6} sm={6} xs={12}>
                    <CreateOutgoingTransaction />
                </Grid.Col>
            </Grid.Row>
        </SiteWrapper>
    );
};

export default IndustraStatement;
