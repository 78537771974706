import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button, Card, Grid, Badge } from 'tabler-react';
import PropTypes from 'prop-types';
import { walletStatusColor, walletStatus } from '../../../../helpers';
import CurrencyBalances from './Wallet/CurrencyBalances';
import MainData from './Wallet/MainData';
import ChargeModal from './Wallet/ChargeModal';
import ChargeList from './Wallet/ChargeList';
import LimitsModal from './Wallet/LimitsModal.js';
import { toast } from 'react-toastify';
import { createApiClient as api } from 'src/services/api-client';
import TransactionList from 'src/components/Content/Users/Wallets/Wallet/TransactionList';

class Wallet extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: false,
            items: [],
            error: null,
            chargeModalIsOpen: false,
            isChargeListOpen: false,
            isTransactionListOpen: false,
            isLimitsModalOpen: false,
        };
    }

    toggleChargeModal = () => {
        this.setState((prevState) => ({
            chargeModalIsOpen: !prevState.chargeModalIsOpen,
        }));
    }

    toggleChargeList = () => {
        this.setState((prevState) => ({
            isChargeListOpen: !prevState.isChargeListOpen,
        }));
    };

    closeChargeList = () => {
        this.setState({
            isChargeListOpen: false,
        });
    };

    toggleTransactionList = () => {
        this.setState((prevState) => ({
            isTransactionListOpen: !prevState.isTransactionListOpen,
        }));
    };

    closeTransactionList = () => {
        this.setState({
            isTransactionListOpen: false,
        });
    };

    toggleLimitsModal = () => {
        this.setState((prevState) => ({
            isLimitsModalOpen: !prevState.isLimitsModalOpen,
        }));
    };

    renderChargeButton = () => {
        const { user } = this.props;

        return (
            <Button outline color='danger' onClick={this.toggleChargeModal} size='sm' disabled={!user.is_verified}>
                Apply charge
            </Button>
        );
    }

    changeWalletStatus = async () => {
        const { wallet } = this.props;

        if (!confirm('Deactivation can not be undone. Would you like to proceed?')) {
            return;
        }

        this.setState({
            isLoading: true,
        });

        try {
            await api()
                .put(`/admin-wallet/wallets/${wallet.id}`, { status: 'inactive' })
                .then((data) => {
                    wallet.is_active = data.data.is_active;
                    wallet.deactivated_at = data.data.deactivated_at;
                })
                .catch((data) => {
                    this.setState({
                        error: data.data.message,
                    });
                });

            toast.success('Wallet successfully deactivated');
        } catch ({ data }) {
            toast.error(`Failed to deactivate wallet: ${data.message}`);
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    render () {
        const { chargeModalIsOpen, isChargeListOpen, isLimitsModalOpen, isTransactionListOpen } = this.state;
        const { wallet, user, isChargeModalButtonVisible } = this.props;

        return (
            <>
                <Card statusColor={walletStatusColor(wallet.is_active)} key={wallet.id} className='mb-0'>
                    <Card.Header>
                        <Card.Title>
                            {wallet.number} / <span className='small'>{wallet.hash}&nbsp;</span>
                            {wallet.is_primary && (
                                <Badge className='ml-2' color='secondary'>
                                    Primary
                                </Badge>
                            )}
                        </Card.Title>
                        <Card.Options>
                            <Button onClick={this.toggleLimitsModal} size='sm' color='secondary' className='mr-2'>
                                Limits
                            </Button>
                            {isChargeModalButtonVisible ? (
                                <Button.List>{this.renderChargeButton()}</Button.List>
                            ) : (
                                wallet.is_active ? (
                                    <Button onClick={this.changeWalletStatus} size='sm' color='secondary' className='mr-2'>
                                        {walletStatus(wallet.is_active)}
                                    </Button>
                                ) : (
                                    <div>{walletStatus(wallet.is_active)}</div>
                                )
                            )}
                        </Card.Options>
                    </Card.Header>
                    <Card.Body>
                        <Grid.Row>
                            <Grid.Col>
                                <MainData wallet_id={wallet.id} />
                            </Grid.Col>
                            <Grid.Col>
                                <CurrencyBalances wallet_id={wallet.id} />
                            </Grid.Col>
                        </Grid.Row>
                    </Card.Body>
                    {isChargeModalButtonVisible && (
                        <ChargeModal
                            wallet={wallet}
                            modalIsOpen={chargeModalIsOpen}
                            onModalClose={this.toggleChargeModal}
                            user={user}
                            closeChargeList={this.closeChargeList}
                        />
                    )}
                    <LimitsModal
                        wallet={wallet}
                        user={user}
                        defaultWalletLimits={this.props.defaultWalletLimits}
                        modalIsOpen={isLimitsModalOpen}
                        onModalClose={this.toggleLimitsModal}
                        onLimitsChange={this.toggleLimitsModal}
                    />
                </Card>
                {/*<ChargeList walletId={wallet.id} toggleChargeList={this.toggleChargeList} isChargeListOpen={isChargeListOpen} />*/}

                <TransactionList
                    walletId={wallet.id}
                    toggleTransactionList={this.toggleTransactionList}
                    isTransactionListOpen={isTransactionListOpen}
                />
            </>
        );
    }
}


Wallet.propTypes = {
    match: PropTypes.object,
    wallet: PropTypes.object.isRequired,
    defaultWalletLimits: PropTypes.array,
    isChargeModalButtonVisible: PropTypes.bool.isRequired,
    user: PropTypes.object,
};

export default withRouter(Wallet);
